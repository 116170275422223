import React, { useEffect, useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function ProfitByDate() {
  let history = useHistory();
  const { date } = useParams();



  const [sells,setSells] = useState([]);
  const [loading,setloading] = useState(true);

  const [accessDenied,setAccess] = useState(true);
 
 
  const [companyProfit,setCompanyProfit] = useState(0);
  const [regularProfit,setRegularProfit] = useState(0);
  const [regularSold,setregularSold] = useState(0);
  const [companySold,setcompanySold] = useState(0);

  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;
  
  useEffect(() => {
     
   const data = {
    date:date
   }

   axios.post('/api/sells',data, {
    headers: { 'Allowed-Source': allowedSource }
})
.then(res=>{
    
    if(res.data.status===200){
        setSells(res.data.sells)
        setAccess(false);
        let company = 0;
        let regular = 0;
        let companySold = 0;
        let regularSold = 0;
        res.data.sells.forEach(element => {
              if(element.company==="yes"){
                company += parseFloat(element.profit,10,2);
                companySold += parseFloat(element.quantity*element.price,10,2);

              }
              else{
                regular +=parseFloat(element.profit,10,2)
                regularSold +=parseFloat(element.quantity*element.price,10,2)

              }
                
        });
        setcompanySold(companySold)
        setregularSold(regularSold)
        setCompanyProfit(company)
        setRegularProfit(regular)
    }
    else{
       
       // history.push('/');
    }
    setloading(false);

})
.catch(error=>{
  
   // history.push('/admin');
}); 

    setloading(false)

    }, [history]);


    axios.interceptors.response.use(function(response){
      return response;
    },function (error){
     if(error.response.status === 403){//access denied
         swal("Forbidden",error.response.data.message,"warning");
         setAccess(true);
         history.push("/admin/dashbboard")
     }
      
    }
  
    );
   


if(loading){
  return(
    <div>Loading..</div>
  )
}
else if(accessDenied){
  return(
<div className='text-black'>Access Denied</div>
  )

}
else{
  return (
    //main div
    <div className='text-black d-flex flex-column'> 
                   <div className='company d-flex flex-column my-3 bg-white'>
                                       <h3 >Company</h3>
                                        <div className='each-row  d-flex '>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Product</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Bying Cost</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Price</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Quantity</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Profit</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Action</span>
                                                
                                        </div>
                                {
                                    sells.map((sell)=>{
                                        if(sell.company==="yes")
                                        return(
                                        <div className='each-row text-black d-flex'>
                                                <span className='border-bottom border-start border-end border-1 border-black w-50'>{sell.product.name}</span>
                                            
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.cost}</span>
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.price}</span>
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.quantity}</span>
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.profit}</span>
                                                <Link to={`/admin/updatehistory/${sell.id}`} className='dec-none border-bottom border-end border-1 border-black w-25'>Update</Link>
                                        </div>

                                        
                                        )
                                    })
                                }
                                <h4 className='bg-warning'>Sold : {companySold} Tk</h4>
                                <h4>Profit : {companyProfit} Tk</h4>
                   </div>

                   <div className='company d-flex flex-column my-3 bg-white'>
                                       <h3 >Regular</h3>
                                        <div className='each-row  d-flex '>
                                                <span className='border-bottom border-top border-start border-end border-1 border-black w-50'>Product</span>
                                            
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Bying Cost</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Price</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Quantity</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Profit</span>
                                                <span className='border-bottom border-top border-end border-1 border-black w-25'>Action</span>
                                                
                                        </div>
                                {
                                    sells.map((sell)=>{
                                        if(sell.company==="no")
                                        return(
                                        <div className='each-row text-black d-flex '>
                                                <span className='border-bottom border-start border-end border-1 border-black w-50'>{sell.product.name}</span>
                                            
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.cost}</span>
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.price}</span>
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.quantity}</span>
                                                <span className='border-bottom border-end border-1 border-black w-25'>{sell.profit}</span>
                                                <Link to={`/admin/updatehistory/${sell.id}`} className='dec-none border-bottom border-end border-1 border-black w-25'>Update</Link>
                                                
                                        </div>

                                        
                                        )
                                    })
                                }
                                 <h4 className='bg-warning'>Sold : {regularSold} Tk</h4>
                                <h4>Profit : {regularProfit} Tk</h4>
                   </div>
                    <h2 className='bg-info'>Total Profit : {regularProfit+companyProfit} Tk</h2>
                    <h2 className='bg-primary'>Total Sold : {companySold+regularSold} Tk</h2>
    </div> //main div
  )
}


}
